import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { message } from 'antd';
import PageTitle from '~/components/PageTitle';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Box from '~/components/Box';
import Button from '~/components/Button';
import HeaderTags from '~/components/HeaderTags';
import Can from '~/components/Can';
import RegisterW41TableSingle from './RegisterW41TableSingle';
import RegisterW41TableAll from './RegisterW41TableAll';
import RegisterW41Edit from './RegisterW41Edit';
import DefaultLayout from '../_layouts/full';

export default function RegisterW41() {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState('customers');
  const [currentCustomer, setCurrentCustomer] = useState(0);
  const [costumersData, setCostumersData] = useState([]);
  const [registersData, setRegistersData] = useState([]);
  const [isEditingNew, setIsEditingNew] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentRecordData, setCurrentRecordData] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchTermCustomers, setSearchTermCustomers] = useState('');
  const [pageCustomers, setPageCustomers] = useState(1);
  const [metaCustomers, setMetaCustomers] = useState({});
  const [perPageCustomers, setPerPageCustomers] = useState(10);
  const [searchTermW41, setSearchTermW41] = useState('');
  const [pageW41, setPageW41] = useState(1);
  const [metaW41, setMetaW41] = useState({});
  const [perPageW41, setPerPageW41] = useState(10);

  const changeCurrentPage = (page, data) => {
    if (page === 'registers') {
      setCurrentCustomer(data);
      setCurrentPage('registers');
    } else if (page === 'customers') {
      setCurrentPage('customers');
    } else if (page === 'editing') {
      setCurrentRecordData(data);
      setIsEditingNew('no');
    }
  };

  const fetchContracts = async id => {
    const { data } = await api.get(`/customers/${id}/contracts`);
    setTags(data);
  };

  const fetchCustomersData = async page => {
    setLoading(true);
    try {
      const { data } = await api.get('/w41/customers', {
        params: {
          page,
          perPageCustomers,
          search: searchTermCustomers,
        },
      });

      setCostumersData(data.data);
      setMetaCustomers(data.meta);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRegisterData = async page => {
    setLoading(true);
    try {
      const { data } = await api.get(`/w41/customer/${currentCustomer}`, {
        params: {
          page,
          perPageW41,
          search: searchTermW41,
        },
      });

      setRegistersData(data.data);
      setMetaW41(data.meta);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    fetchContracts(currentCustomer);
    setLoading(false);
  };

  const deleteRegister = async id => {
    setLoading(true);
    try {
      await api.post(`/w41/delete/${id}`);
      message.success(t('messages:successDeleteW41'));
      fetchRegisterData();
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const sendDataToServer = async dataToSend => {
    setLoading(true);
    try {
      await api.post('/w41/update/', dataToSend);
      if (dataToSend.id === 0) message.success(t('messages:successCreateW41'));
      else message.success(t('messages:successUpdateW41'));
      fetchRegisterData();
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCustomersData(pageCustomers);
  }, [pageCustomers, searchTermCustomers, perPageCustomers]);

  useEffect(() => {
    if (currentPage === 'registers') fetchRegisterData(pageW41);
  }, [currentPage, pageW41, searchTermW41, perPageW41]);

  const handleRecordChange = () => {
    fetchRegisterData();
  };

  const newRegistry = () => {
    if (currentPage === 'customers') {
      setCurrentCustomer(0);
      setCurrentRecordData([]);
    } else if (currentPage === 'registers') {
      const tmp = [];
      tmp.customer_id = currentCustomer;
      setCurrentRecordData(tmp);
    }

    setIsEditingNew('yes');
  };

  return (
    <DefaultLayout>
      {currentPage === 'customers' ? (
        <PageTitle title={t('screens:w41.title')}>
          <Can permission="@w41/edit">
            <Button onClick={newRegistry} color="primary">
              <FaPlus />
              {t('screens:w41.register_new')}
            </Button>
          </Can>
        </PageTitle>
      ) : (
        <>
          <PageTitle
            title={costumersData.find(element => element.id === currentCustomer).name}
            // subtitle={costumersData.find(element => element.id === currentCustomer).name ? ` - ${costumersData.find(element => element.id === currentCustomer).name}` : null}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
                {tags.length > 0 &&
                  tags.map(tag => {
                    if (tag && tag.active) {
                      return (
                        <HeaderTags
                          color={tag && tag.product && tag.product.subcategory && tag.product.subcategory.color}
                          text={tag && tag.product && tag.product.subcategory && tag.product.subcategory.name}
                        />
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
          </PageTitle>
          <PageTitle size={2} title="">
            <Can permission="@w41/edit">
              <Button onClick={newRegistry} color="primary">
                <FaPlus />
                {t('screens:w41.register_new')}
              </Button>
            </Can>
          </PageTitle>
        </>
      )}
      <Box>
        {currentPage === 'customers' ? (
          <Can permission="@w41/view">
            <RegisterW41TableAll
              loading={loading}
              costumersData={costumersData}
              handleEdit={changeCurrentPage}
              setPage={setPageCustomers}
              meta={metaCustomers}
              perPage={perPageCustomers}
              setPerPage={setPerPageCustomers}
              setSearchTerm={setSearchTermCustomers}
            />
          </Can>
        ) : (
          ''
        )}
        {currentPage === 'registers' ? (
          <Can permission="@w41/view">
            <RegisterW41TableSingle
              loading={loading}
              customer={currentCustomer}
              registers={registersData}
              changeCurrentPage={changeCurrentPage}
              changeRegisterData={handleRecordChange}
              changeCurrentRecord={setCurrentRecordData}
              deleteRegister={deleteRegister}
              setPage={setPageW41}
              meta={metaW41}
              perPage={perPageW41}
              setPerPage={setPerPageW41}
              setSearchTerm={setSearchTermW41}
            />
          </Can>
        ) : (
          ''
        )}
        {isEditingNew !== 0 ? (
          <Can permission="@w41/edit">
            <RegisterW41Edit
              handleBack={setIsEditingNew}
              record={currentRecordData}
              sendDataToServer={sendDataToServer}
              news={isEditingNew}
            />
          </Can>
        ) : (
          ''
        )}
      </Box>
    </DefaultLayout>
  );
}
