import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaPencilAlt, FaBuilding, FaEye, FaFileAlt, FaPaperclip, FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, Tag } from 'antd';
import Pagination from '~/components/Pagination';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { Table, TableActions, TableHeader } from '~/components/Table';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import history from '~/services/history';

export default function Customers() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [recordData, setRecordData] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [perPage, setPerPage] = useState(10);
  const permissions = useSelector(state => state.user && state.user.permissions);

  const fetchSuppliers = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/suppliers', {
        params: {
          search: searchTerm,
          page,
          perPage,
        },
      });

      setRecordData(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSupplier = id => {
    history.push(`/suppliers/${id}`);
  };

  useEffect(() => {
    fetchSuppliers();
  }, [page, searchTerm, perPage]);

  console.log(permissions);

  const tableColumns = [
    {
      title: t('screens:suppliers.type'),
      dataIndex: 'type',
      key: 'type',
      render: (text, record) =>
        record.type === 'Juridica' ? <FaBuilding title="Jurídica" /> : <FaUser title="Física" />,
    },
    {
      title: t('screens:suppliers.legalName'),
      dataIndex: 'legalName',
      key: 'legalName',
      render: (text, record) => {
        console.log(record);
        return record.private ? <span>{record.private?.Nome}</span> : <span>{record.legal?.RazaoSocial}</span>;
      },
    },
    {
      title: t('screens:suppliers.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        console.log(record);
        return record.private ? <span>{record.private?.Nome}</span> : <span>{record.legal?.NomeFantasia}</span>;
      },
    },
    {
      title: t('screens:suppliers.document'),
      dataIndex: 'document',
      key: 'document',
      render: (text, record) => <span>{record.legal ? record.legal?.Documento : record.private?.Documento}</span>,
    },
    {
      title: t('screens:suppliers.active'),
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => (record.active ? <Tag color="green">SIM</Tag> : <Tag color="red">NÃO</Tag>),
    },
    {
      // Title: Just a space on table destinated to show the qnty of each customer contracts;
      width: '10px',
      dataIndex: 'active-contract',
      key: 'active-contract',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@contract/view">
            {record?.contractsAmount > 0 && (
              <Button title="Contratos" style={{ border: 'none', padding: '0' }}>
                <FaFileAlt />
                <b style={{ fontSize: '10px', fontWeight: '400' }}>{record.contractsAmount}</b>
              </Button>
            )}
          </Can>
        </TableActions>
      ),
    },
    {
      // Title: Just a space on table destinated to show the qnty of each suppliers files;
      width: '10px',
      dataIndex: 'supplier-file',
      key: 'supplier-file',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@contract/view">
            {record?.countFiles > 0 && (
              <Button title="Anexos" style={{ border: 'none', padding: '0', cursor: 'default' }}>
                <FaPaperclip />
                <b style={{ fontSize: '10px', fontWeight: '400' }}>{record.countFiles}</b>
              </Button>
            )}
          </Can>
        </TableActions>
      ),
    },
    {
      title: t('screens:suppliers.actions'),
      width: '100px',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@supplier/edit">
            <Button title={t('messages:edit')} onClick={() => handleSupplier(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@supplier/edit">
            <Can permission="@supplier/view">
              <Button title={t('messages:view')} onClick={() => handleSupplier(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:suppliers.title')} />
      <Box>
        <TableHeader>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table
          rowKey="id"
          pagination={false}
          onRow={record => {
            return {
              onDoubleClick: () => {
                handleSupplier(record.id);
              },
            };
          }}
          loading={loading}
          columns={tableColumns}
          dataSource={recordData}
        />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Box>
    </DefaultLayout>
  );
}
