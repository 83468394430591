import React, { useEffect, useState } from 'react';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { Container } from '~/components/Box/styles';
import Logo from '~/components/Logo';
import Box from '~/components/Box';
import { Col, Input, message } from 'antd';
import Button from '~/components/Button';
import history from '~/services/history';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { ButtonGlobalStyle } from './styles';
import BaseLayout from '../_layouts/base';

export default function Twofa() {
  const { user } = useLocation().state || {};
  const [qrCode, setQrCode] = useState('');
  const [code, setCode] = useState('');
  const [createQr, setCreateQr] = useState(false);
  // const [rememberCode, setRememberCode] = useState(false);

  const fetchQrCode = async () => {
    try {
      const { data } = await api.post('/users/2fa/setup');
      setQrCode(data.qrCodeUrl);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
  };

  const makeCode = async token => {
    try {
      const { data } = await api.post('/users/2fa/verify', { token });

      if (data.message) {
        message.success('Código verificado');
        history.push('/customers');
      } else {
        message.error('Código inválido');
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (user?.google2fa_secret === null || user?.google2fa_secret === undefined) {
      setCreateQr(true);
    }

    if (createQr) {
      fetchQrCode();
    }
  }, [createQr]);

  const renderQrCode = () => {
    if (qrCode.startsWith('data:image')) {
      return <img src={qrCode} alt="qr-code" width="260px" />;
    }
    return <div dangerouslySetInnerHTML={{ __html: qrCode }} />;
    // code
  };

  return (
    <BaseLayout>
      <ButtonGlobalStyle />
      <Container>
        <Logo height="70px" width="201px" margin="20px 0 40px 0" />
        {createQr ? (
          <>
            <Box>
              <h1>Leia o código QR com o seu autenticador de preferência e digite o código gerado no campo abaixo</h1>
            </Box>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              {renderQrCode()}
              <Input onChange={event => setCode(event.target.value)} style={{ width: '50%' }} placeholder="Código" />
            </Col>
          </>
        ) : (
          <>
            <Box>
              <h1>Digite o código gerado pelo seu autenticador de preferência no campo abaixo</h1>
            </Box>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Input onChange={event => setCode(event.target.value)} style={{ width: '50%' }} placeholder="Código" />
            </Col>
          </>
        )}
        <Col style={{ display: 'flex', gap: '20px', margin: '20px' }}>
          <Button size="large" block margin="40px 0 0 0" color="primary" onClick={() => makeCode(code)}>
            Autenticar
          </Button>
        </Col>
        {/* <Checkbox className="remember-me" name="remember" onChange={() => setRememberCode(!rememberCode)}>
          Lembrar da autenticação por 30 dias
        </Checkbox> */}
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            margin: '20px',
            flexDirection: 'column',
          }}
        >
          <h4>Perdeu seu autenticador?</h4>
          <Button onClick={() => setCreateQr(true)}>Redefinir 2FA</Button>
        </Col>
      </Container>
    </BaseLayout>
  );
}
