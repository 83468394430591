import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Spin } from 'antd';
import Can from '~/components/Can';
import Row from '~/components/Row';
import Button from '~/components/Button';
import DefautLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import history from '~/services/history';
import ClientsPerMonth from '~/components/DashboardCharts/clientsPerMonth';
import AverageTicketBySubCategory from '~/components/DashboardCharts/averageTicketBySubCategory';
import WonAndLostProducts from '~/components/DashboardCharts/wonAndLostProducts';
import WonAndLostServices from '~/components/DashboardCharts/wonAndLostServices';
import CategoriesChart from '~/components/DashboardCharts/categoriesChart';

export default function Home() {
  // const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [contractsWillExpire, setContractsWillExpire] = useState([]);
  const [expiredContracts, setExpiredContracts] = useState([]);
  const [disabledContracts, setDisabledContracts] = useState([]);
  const permissions = useSelector(state => state.user && state.user.permissions);

  const fetchContractsWillExpire = async () => {
    const { data } = await api.get('/contracts/willExpire');
    setContractsWillExpire(data);
  };

  const fetchExpiredContracts = async () => {
    const { data } = await api.get('/contracts/expired');
    setExpiredContracts(data);
  };

  const fetchDisabledContracts = async () => {
    const { data } = await api.get('/contracts/disabled');
    setDisabledContracts(data);
  };

  const fetchRecords = async () => {
    setLoading(true);
    try {
      if (permissions && permissions.includes('@dashboard/view')) {
        await Promise.all([fetchContractsWillExpire(), fetchExpiredContracts(), fetchDisabledContracts()]);
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  return (
    <DefautLayout>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <PageTitle title="Dashboard" subtitle="Bem vindo!" />
        </Col>
        <Can permission="@dashboard/view">
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            <Button
              style={{ marginRight: '10px' }}
              color="primary"
              onClick={() => {
                history.push('/contracts?status=willExpire');
              }}
            >
              <Spin spinning={loading}>
                {contractsWillExpire.meta && contractsWillExpire.meta.total} contratos à expirar
              </Spin>
            </Button>
            <Button
              style={{ marginRight: '10px' }}
              color="primary"
              onClick={() => {
                history.push('/contracts?status=expired');
              }}
            >
              <Spin spinning={loading}>{expiredContracts.meta && expiredContracts.meta.total} contratos expirados</Spin>
            </Button>
            <Button
              color="primary"
              onClick={() => {
                history.push('/contracts?status=disabled');
              }}
            >
              <Spin spinning={loading}>
                {disabledContracts.meta && disabledContracts.meta.total} contratos inativados
              </Spin>
            </Button>
          </div>
        </Can>
      </Row>
      <Can permission="@dashboard/view">
        <Spin spinning={loading}>
          <ClientsPerMonth />
          <AverageTicketBySubCategory />
          <WonAndLostProducts />
          <WonAndLostServices />
          <CategoriesChart />
        </Spin>
      </Can>
    </DefautLayout>
  );
}
