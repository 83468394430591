import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, message, Spin, Modal } from 'antd';
import PropTypes from 'prop-types';
import { Input, Form } from 'formik-antd';
import { FaUserTie, FaDollarSign, FaShoppingCart, FaStar } from 'react-icons/fa';
import 'moment/locale/pt-br';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import { ModalFooter } from '~/components/Modal';
import errorHandler from '../../Utils/errorHandler';
import api from '../../services/api';
import { dynamicFormatPhone } from '../../Utils';

const initialValues = { name: '', email: '', phone: '', type: ['padrao'] };

export default function ContactsForm({ visible, onClose, record }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState(initialValues);

  const handleChangeType = async type => {
    setLoading(true);
    try {
      if (recordData.type.includes(type)) {
        const newType = await recordData.type.filter(item => item !== type);
        recordData.type = newType;
      } else {
        await recordData.type.push(type);
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      values.phone = values.phone ? values.phone.replace(/[^0-9,]*/g, '') : null;
      await api.put(`/people/${values.id}`, values);

      if (values.customers.length >= 1) {
        api.post(`/customers/${values.customers[0].id}/updated`);
      }

      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      record.phone = record.phone ? dynamicFormatPhone(record.phone) : null;
      setRecordData(record);
    }
  }, [visible]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string()
      .email()
      .nullable(),
    phone: Yup.string().nullable(),
  });

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={recordData}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSave}
    >
      {({ errors, isSubmitting, values, submitForm, resetForm }) => (
        <Modal
          title={recordData && `${t('screens:editing')}: ${recordData.email}`}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          footer={
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} cancelColor="default" />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Input type="hidden" name="id" />
              <Row>
                <Col xs={24}>
                  <Row>
                    <FormControl
                      error={errors.name}
                      cols={{ xs: 24 }}
                      field="name"
                      label={t('screens:contacts.data.name')}
                      required
                    >
                      <Input name="name" style={{ textTransform: 'uppercase' }} />
                    </FormControl>
                    <FormControl
                      error={errors.email}
                      cols={{ xs: 24 }}
                      field="email"
                      label={t('screens:contacts.data.email')}
                    >
                      <Input name="email" type="email" />
                    </FormControl>
                    <FormControl
                      error={errors.phone}
                      cols={{ xs: 24 }}
                      field="phone"
                      label={t('screens:customers.data.phone')}
                    >
                      <Input
                        name="phone"
                        maxLength="15"
                        id="phone1"
                        onChange={e => {
                          setTimeout(() => {
                            setRecordData({
                              ...values,
                              phone: dynamicFormatPhone(e.target.value.replace(/[^0-9,]*/g, '')),
                            });
                          }, 100);
                        }}
                        placeholder="(  ) ____-____"
                      />
                    </FormControl>
                    <FormControl field="estoque" label={t('screens:contacts.data.type')} cols={{ xs: 24 }}>
                      <Row>
                        <Button
                          style={{ background: 'none' }}
                          title={t('messages:buy')}
                          onClick={() => {
                            handleChangeType('compras');
                          }}
                        >
                          <FaShoppingCart
                            color={recordData.type.includes('compras') ? '#0f4c81' : '#aaa'}
                            name="buy"
                            size={18}
                            title={t('messages:buy')}
                            style={{ marginRight: '5px' }}
                          />
                        </Button>
                        <Button
                          style={{ background: 'none' }}
                          title={t('messages:director')}
                          onClick={() => {
                            handleChangeType('diretoria');
                          }}
                        >
                          <FaUserTie
                            color={recordData.type.includes('diretoria') ? '#0f4c81' : '#aaa'}
                            name="director"
                            size={18}
                            title={t('messages:director')}
                            style={{ marginRight: '5px' }}
                          />
                        </Button>
                        <Button
                          style={{ background: 'none' }}
                          title={t('messages:financial')}
                          onClick={() => {
                            handleChangeType('financeiro');
                          }}
                        >
                          <FaDollarSign
                            color={recordData.type.includes('financeiro') ? '#0f4c81' : '#aaa'}
                            name="financial"
                            size={18}
                            title={t('messages:financial')}
                            style={{ marginRight: '5px' }}
                          />
                        </Button>
                        <Button
                          style={{ background: 'none' }}
                          title={t('messages:default')}
                          onClick={() => {
                            handleChangeType('padrao');
                          }}
                        >
                          <FaStar
                            color={recordData.type.includes('padrao') ? '#0f4c81' : '#aaa'}
                            name="default"
                            size={18}
                            title={t('messages:default')}
                            style={{ marginRight: '5px' }}
                          />
                        </Button>
                      </Row>
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

ContactsForm.defaultProps = {
  record: { phone: '' },
  visible: false,
  onClose: () => {},
};

ContactsForm.propTypes = {
  record: PropTypes.objectOf(PropTypes.string),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};
