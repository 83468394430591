import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Form, Switch, Input } from 'formik-antd';
import { Modal, message, Spin, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

const initialValues = {
  name: '',
  email: '',
  active: true,
};

export default function UserForm({ visible, onClose, userID }) {
  /* eslint-disable react/jsx-wrap-multilines */
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const permissions = useSelector(state => state.user && state.user.permissions);

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (values.id) {
        await api.put(`/users/${values.id}`, values);
      } else {
        await api.post('/users', values);
      }
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      if (!userID) {
        setRecordData(initialValues);
      } else {
        const { data } = await api.get(`/users/${userID}`);
        setRecordData(JSON.parse(data));
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
  }, [visible]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string()
      .email()
      .required(),
  });

  return (
    <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={userSchema}>
      {({ errors, isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.name}` : t('screens:users.title')}
          onCancel={onClose}
          style={{ maxWidth: '1100px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            <ModalFooter
              type={permissions && !permissions.includes('@contract/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <FormControl
                  cols={{ xs: 10 }}
                  error={errors.name}
                  field="name"
                  label={t('screens:users.data.name')}
                  required
                >
                  <Input name="name" disabled={permissions && !permissions.includes('@config/edit')} />
                </FormControl>
                <FormControl
                  cols={{ xs: 10 }}
                  error={errors.email}
                  field="email"
                  label={t('screens:users.data.email')}
                  required
                >
                  <Input type="email" name="email" disabled={permissions && !permissions.includes('@config/edit')} />
                </FormControl>
                <FormControl cols={{ xs: 4 }} field="active" label={t('screens:users.data.active')}>
                  <Switch name="active" disabled={permissions && !permissions.includes('@config/edit')} />
                </FormControl>
              </Row>
              <Row>
                <FormControl field="tfa_auth">
                  <Checkbox
                    name="tfa_auth"
                    style={{ marginTop: '20px' }}
                    checked={values.tfa_auth}
                    onChange={e => setFieldValue('tfa_auth', e.target.checked)}
                  >
                    <p>Habilitar autenticação 2FA</p>
                  </Checkbox>
                </FormControl>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}

UserForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userID: PropTypes.string,
};

UserForm.defaultProps = {
  userID: null,
};
