import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaLockOpen, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { Input, Popconfirm, Icon, message, Select } from 'antd';

import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import Box from '~/components/Box';
import { TableHeader, Table, TableActions } from '~/components/Table';
import DefaultLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import RoleForm from './form';
import PermissionForm from './permissions';

export default function Roles() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showRoleForm, setShowRoleForm] = useState(false);
  const [showPermissionForm, setShowPermissionForm] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});

  const fetchRecords = async page => {
    setLoading(true);
    try {
      const { data } = await api.get('/roles', {
        params: {
          search: searchTerm,
          page,
          perPage,
        },
      });
      setRecordList(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleRole = (role = false) => {
    if (role) {
      setSelectedRecord(role);
      setShowRoleForm(true);
    } else {
      setShowRoleForm(true);
    }
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`/roles/${id}`);
      fetchRecords();
      message.success(t('messages:success'));
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handlePermissions = record => {
    setSelectedRecord(record);
    setShowPermissionForm(true);
  };

  useEffect(() => {
    fetchRecords(page);
  }, [page, searchTerm]);

  useEffect(() => {
    if (!showRoleForm && !showPermissionForm) {
      fetchRecords(page);
      setSelectedRecord(null);
    }
  }, [showRoleForm, showPermissionForm]);

  const tableColumns = [
    {
      title: t('screens:roles.data.name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('screens:roles.data.slug'),
      key: 'slug',
      dataIndex: 'slug',
    },
    {
      key: 'actions',
      width: '140px',
      render: (text, record) => {
        return (
          <TableActions>
            <Button onClick={() => handleRole(record)} title={t('messages:edit')}>
              <FaPencilAlt />
            </Button>
            <Popconfirm
              title={t('messages:confirmDelete')}
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              okText={t('messages:delete')}
              cancelText={t('messages:no')}
              onConfirm={() => handleDelete(record.id)}
            >
              <Button title={t('messages:edit')}>
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
            <Button onClick={() => handlePermissions(record)}>
              <FaLockOpen />
            </Button>
          </TableActions>
        );
      },
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:roles.title')}>
        <Button color="primary" onClick={handleRole}>
          <FaPlus />
          {t('screens:roles.btnNew')}
        </Button>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table rowKey="id" loading={loading} dataSource={recordList} columns={tableColumns} pagination={false} />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
        />
        <Select
          name="number"
          onChange={value => {
            setPerPage(value);
          }}
          value={perPage}
          style={{ width: '70px' }}
        >
          <Select.Option value={10}>10</Select.Option>
          <Select.Option value={20}>20</Select.Option>
          <Select.Option value={30}>30</Select.Option>
          <Select.Option value={40}>40</Select.Option>
          <Select.Option value={50}>50</Select.Option>
          <Select.Option value={100}>100</Select.Option>
        </Select>
      </Box>
      <RoleForm visible={showRoleForm} role={selectedRecord} onClose={() => setShowRoleForm(false)} />
      <PermissionForm
        visible={showPermissionForm}
        roleID={selectedRecord?.id}
        rolePermissions={selectedRecord ? selectedRecord.permissions : null}
        onClose={() => setShowPermissionForm(false)}
      />
    </DefaultLayout>
  );
}
