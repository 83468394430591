import React, { useState, useEffect } from 'react';
import { FaPlus, FaPencilAlt, FaRegTrashAlt, FaTools, FaBox, FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Tag, Popconfirm, message, Input, Icon, Switch } from 'antd';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { Table, TableActions, TableHeader } from '~/components/Table';
import history from '~/services/history';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Pagination from '~/components/Pagination';
import { SpanUpper } from '~/styles/default';

export default function Products() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState(null);

  const fetchData = async page => {
    setLoading(true);
    try {
      const { data } = await api.get('/products', {
        params: {
          page,
          perPage,
          search: searchTerm,
        },
      });

      setTableData(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/products/create');
  };

  const handleEdit = async id => {
    history.push(`/products/${id}/edit`);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`products/${id}`);
      message.success(t('messages:successDeleteProduct'));
      fetchData(page);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeActive = async record => {
    setLoading(true);
    try {
      await api.put(`/products/${record.id}`, record);
      fetchData(page);
      message.success(t('messages:success'));
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(page);
  }, [page, perPage, searchTerm]);

  const tableColumns = [
    {
      title: t('screens:products.data.type'),
      dataIndex: 'product',
      key: 'product',
      align: 'center',
      width: '90px',
      render: (text, record) => {
        return record.product ? <FaBox size={18} /> : <FaTools size={18} />;
      },
    },
    {
      title: t('screens:products.data.category'),
      key: 'subcategory.category.name',
      render: (text, record) => <SpanUpper>{record.subcategory.category.name}</SpanUpper>,
    },
    {
      title: t('screens:products.data.subcategory'),
      key: 'subcategory.name',
      render: (text, record) => <SpanUpper>{record.subcategory.name}</SpanUpper>,
    },
    {
      title: t('screens:products.data.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <SpanUpper>
          {record.name}
          {record.description && (
            <Button style={{ background: 'none', margin: 0, padding: 0, cursor: 'text' }} title={record.description}>
              <small style={{ display: 'block', font: '12px "Roboto",sans-serif', color: '#5f5f5f' }}>
                {record.description && record.description.length > 25
                  ? `${record.description.substring(0, 25)}..`
                  : record.description}
              </small>
            </Button>
          )}
        </SpanUpper>
      ),
    },
    {
      title: t('screens:products.data.price'),
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => <span>R$ {record.price}</span>, // Changed numer format to 2 decimal digits and , instead of .
    },
    {
      title: t('screens:products.data.recurrence'),
      dataIndex: 'recurrence',
      key: 'recurrence',
      render: (text, record) => {
        switch (record.recurrence && record.recurrence.toString()) {
          case '1':
            return <Tag color="blue">Mensal</Tag>;
          case '3':
            return <Tag color="yellow">Trimestral</Tag>;
          case '6':
            return <Tag color="green">Semestral</Tag>;
          case '12':
            return <Tag color="red">Anual</Tag>;
          case '24':
            return <Tag color="geekblue">Bianual</Tag>;
          case '36':
            return <Tag color="purple">Trianual</Tag>;
          default:
            return null;
        }
      },
    },
    {
      title: t('screens:products.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return (
          <TableActions>
            <Can permission="@product/edit">
              <Button style={{ margin: 'auto' }} title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
                <FaPencilAlt />
              </Button>
            </Can>
            <Cannot permission="@product/edit">
              <Can permission="@product/view">
                <Button style={{ margin: 'auto' }} title={t('messages:view')} onClick={() => handleEdit(record.id)}>
                  <FaEye />
                </Button>
              </Can>
            </Cannot>
            <Can permission="@product/delete">
              <Popconfirm
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                title={t('messages:confirmDelete')}
                onConfirm={() => {
                  handleDelete(record.id);
                }}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button title={t('messages:delete')}>
                  <FaRegTrashAlt />
                </Button>
              </Popconfirm>
            </Can>
            <Can permission="@product/delete">
              <Switch
                style={{ marginTop: '5px' }}
                name="active"
                checked={record.active}
                onChange={e => {
                  record.active = e;
                  delete record.files;
                  handleChangeActive(record);
                }}
              />
            </Can>
          </TableActions>
        );
      },
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:products.title')}>
        <Can permission="@product/edit">
          <Button color="primary" loading={loading} onClick={handleNew}>
            <FaPlus />
            {t('screens:products.btnNew')}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table loading={loading} columns={tableColumns} dataSource={tableData} pagination={false} />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Box>
    </DefaultLayout>
  );
}
