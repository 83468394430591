import React, { useState, useEffect, useRef } from 'react';
// import { FaPlus, FaPencilAlt, FaRegTrashAlt, FaTools, FaBox, FaFileExcel, FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import Button from '~/components/Button';
import Box from '~/components/Box';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

export default function InventoryProductsTags(props) {
  const { t } = useTranslation();
  const {
    match: {
      params: { tag },
    },
  } = props;
  // const [loading, setLoading] = useState(false);
  const [inventoryProduct, setInventoryProduct] = useState({});

  const [id, setId] = useState(tag);
  const [found, setFound] = useState(true);
  const inputRef = useRef(null);

  const fetchData = async () => {
    // setLoading(true);
    try {
      const { data } = await api.get(`/inventoryProducts/tag/${id}`);
      setInventoryProduct(JSON.parse(data));
      setFound(true);
    } catch (error) {
      setFound(false);
      console.log(error);
      errorHandler(error);
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    // <Can permission="@inventoryProducts/view">
    <Box>
      {found && (
        <table>
          <tbody>
            <tr>
              <td colSpan="2" style={{ textAlign: 'center' }}>
                <h2>PATRIMÔNIO #{inventoryProduct.code_seq}</h2>
              </td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.product').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.name}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.inventoryCategory').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.inventoryCategory ? inventoryProduct.inventoryCategory.name : ''}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.inventorySubCategory').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.inventorySubCategories ? inventoryProduct.inventorySubCategories.name : ''}</td>
            </tr>

            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.inventoryBrand').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.inventoryBrand ? inventoryProduct.inventoryBrand.name : ''}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.model').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.model}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.serial').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.serial}</td>
            </tr>

            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.department').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.department}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.responsible').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.responsible}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.acquisition_nf').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.acquisition_nf}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.provider').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.provider}</td>
            </tr>

            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.acquisition_date').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.acquisition_date}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.acquisition_value').toLocaleUpperCase()}:</b>
              </td>
              <td>R$ {inventoryProduct.acquisition_value}</td>
            </tr>
            <tr>
              <td>
                <b>{t('screens:inventoryProducts.data.conservation').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.conservation}</td>
            </tr>

            <tr>
              <td style={{ verticalAlign: 'top' }}>
                <b>{t('screens:inventoryProducts.data.notes').toLocaleUpperCase()}:</b>
              </td>
              <td>{inventoryProduct.notes}</td>
            </tr>
          </tbody>
        </table>
      )}
      {!found && (
        <table>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center' }}>
                <h2>PATRIMÔNIO #{id}</h2>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'center' }}>
                <h2>NÃO ENCONTRADO</h2>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <div>
        <Input
          ref={inputRef}
          name="id"
          style={{
            textTransform: 'uppercase',
            marginTop: '1em',
          }}
          placeholder={id}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.key === 'NumpadEnter') {
              setId(inputRef.current.state.value);
            }
          }}
        />
        <Button
          style={{
            marginTop: '1em',
            width: '100%',
          }}
          onClick={() => {
            setId(inputRef.current.state.value);
          }}
        >
          Buscar
        </Button>
      </div>
    </Box>
    // </Can>
  );
}

InventoryProductsTags.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tag: PropTypes.string,
    }),
  }).isRequired,
};
