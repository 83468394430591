/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { format, isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import { Spin, message, Col, Popconfirm, Icon, Tag, notification } from 'antd';
import { Input, Select, Form, Radio, Switch } from 'formik-antd';
import {
  FaMale,
  FaUserTie,
  FaDollarSign,
  FaShoppingCart,
  FaStar,
  FaBuilding,
  FaPlus,
  FaRegTrashAlt,
  FaSearch,
  FaPen,
  FaTools,
  FaBox,
  FaInfo,
  FaPaperclip,
  FaClone,
  FaPencilAlt,
  FaEye,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as cep from 'cep-promise';
import MaskedInput from 'antd-mask-input';
import FormikErrorFocus from 'formik-error-focus';
import Pagination from '~/components/Pagination';
import { validateCpf, dynamicFormatPhone, formatPhone, normalizeString } from '~/Utils';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import { Table, TableActions } from '~/components/Table';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
import history from '~/services/history';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import HeaderTags from '~/components/HeaderTags';
import InfoModal from '~/pages/Contracts/infoModal';
import { SpanUpper } from '~/styles/default';
import { HeaderInfo } from './styles';
import ContractsForm from '../Contracts/form';
import { canCostValueView, permissionDisable } from '../Contracts/permissions';

const initialValues = {
  business: true,
  active: true,
};

const contactInitialValues = {
  name: '',
  email: '',
  phone: '',
  type: [],
};

export default function Customers(props) {
  /* eslint-disable react/jsx-curly-newline */
  const { t } = useTranslation();
  const {
    match: {
      params: { id },
    },
  } = props;
  const [loading, setLoading] = useState(false);
  const [customerType, setCustomerType] = useState(true);
  const [recordData, setRecordData] = useState(initialValues);
  const [contractsData, setContractsData] = useState([]);
  const [showContractsForm, setShowContractsForm] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [selectedContract, setSelectedContract] = useState([]);
  const [selectedEditContract, setSelectedEditContract] = useState({});
  const [selectedContact, setSelectedContact] = useState(contactInitialValues);
  const { states, recurrences, addressesTypes, customerGenders } = useSelector(state => state.app);
  const { permissions } = useSelector(state => state.user);
  const [stateOptions, setStateOptions] = useState(states);
  const [contactsData, setContactsData] = useState([]);
  const [tableContactsData, setTableContactsData] = useState([]);
  const [newCustomer, setNewCustomer] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [perPage, setPerPage] = useState(10);
  const { TextArea } = Input;
  const [tags, setTags] = useState([]);
  const role = '@admin';

  const storeFilesToDatabase = async (files, contratoId) => {
    try {
      const formData = new FormData();
      files.forEach(file => {
        const originFile = file.originFileObj || file;
        if (originFile) {
          formData.append('files[]', originFile);
        }
      });

      await api.post(`/contracts/files/${contratoId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Erro ao armazenar arquivos:', error);
    }
  };

  const handleFindAddress = async (value, values) => {
    setLoading(true);
    try {
      const zipcode = value.replace(/[^0-9,]*/g, '');
      const address = await cep(zipcode);
      setRecordData({
        ...values,
        zipcode,
        city: address.city,
        state: states.filter(state => state.id === address.state)[0].id,
        neighborhood: address.neighborhood,
        address: address.street,
      });
    } catch (error) {
      message.error('Endereço não localizado!');
    }
    setLoading(false);
  };

  const handleFindCompany = async (value, values) => {
    setLoading(true);
    const cnpj = value.replace(/[^0-9,]*/g, '');
    try {
      if (cnpj.length === 14) {
        const company = await api.post('/tax', {
          ssn: cnpj,
        });

        const data = {
          ...values,
          ssn: cnpj,
          nickname: company.data.fantasia, // fantasia
          name: company.data.razao_social, // razao
          phone: company.data.phone,
          cep: company.data.cep.replace(/[^0-9,]*/g, ''),
        };

        setRecordData(data);
        handleFindAddress(company.data.cep.replace(/[^0-9,]*/g, ''), data);

        message.success('Empresa encontrada!');
      }
    } catch (error) {
      console.log('error -> handleFindCompany -> ', error);
      message.error('Empresa não localizada!');
    }
    setLoading(false);
  };

  const handleStateSearch = value => {
    const length = value.length < 2 || value.length > 2;
    const options = states.filter(item => {
      const search = normalizeString(value.toLowerCase());
      const val = normalizeString(length ? item.name.toLowerCase() : item.id.toLowerCase());
      return val.includes(search);
    });
    setStateOptions(options);
  };

  const handleDeletePerson = async person_id => {
    try {
      await api.delete(`/people/${person_id}/customer/${id}`);
      await api.delete(`/people/${person_id}`);
    } catch (error) {
      console.log('error -> handleDeletePerson -> ', error);
      console.log(error);
      errorHandler(error);
    }
  };

  const handleContactSave = async customerId => {
    try {
      contactsData.map(async contact => {
        if (contact.delete && contact.id) {
          await handleDeletePerson(contact.id);
        }
      });
      contactsData.map(async contact => {
        if (contact.delete && !contact.id) {
          const contacts = contactsData.filter(contato => contato !== contact);
          setContactsData(contacts);
        }
      });

      contactsData.map(async contact => {
        if (!contact.delete) {
          contact.phone = contact.phone && contact.phone.replace(/[^0-9,]*/g, '');
        }
      });

      await api.post(`/people/${customerId}/customer`, contactsData);
    } catch (error) {
      console.log(error);
      console.log(error);
      errorHandler(error);
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    values.active = recordData.active;
    const ssn = values.ssn.replace(/[^0-9,]*/g, '');
    if (values.business === false && !validateCpf(ssn)) {
      const errors = {};
      errors.ssn = 'CPF inválido';
      message.error('CPF inválido');
      setErrors(errors);
    } else {
      try {
        if (values.id) {
          values.ssn = values.ssn ? values.ssn.replace(/[^0-9,]*/g, '') : null;
          values.phone = values.phone ? values.phone.replace(/[^0-9,]*/g, '') : null;

          await handleContactSave(values.id);

          delete values.files;

          await api.put(`/customers/${values.id}`, values);
          api.post(`/customers/${values.id}/updated`);

          history.push('/customers');
          message.success(t('messages:successUpdateCustomer'));
        } else {
          values.ssn = values.ssn.replace(/[.-]/g, '');
          values.phone = values.phone ? values.phone.replace(/[^0-9,]*/g, '') : null;

          const { data: customerData } = await api.post('/customers', values);
          const customerCreated = JSON.parse(customerData);

          await handleContactSave(customerCreated.id);
          api.post(`/customers/${customerCreated.id}/created`).catch(error => {
            console.error('Erro ao criar cliente na API externa:', error);

            notification.error({
              message: 'Erro ao criar cliente na API do Bom Controle',
              description: error.response?.data?.message || 'Ocorreu um erro inesperado.',
            });
          });

          if (newCustomer.length > 0) {
            // Mapeia cada contrato para uma Promise
            const promises = newCustomer.map(async contract => {
              try {
                contract.customer_id = customerCreated.id;
                const { data: contractData } = await api.post('/contracts', contract);

                if (contract.uploadFiles && contract.uploadFiles.length > 0) {
                  await storeFilesToDatabase(contract.uploadFiles, JSON.parse(contractData).id);
                }
              } catch (error) {
                console.error('Error creating contract:', error);
              }
            });

            // Espera que todas as Promises sejam resolvidas
            await Promise.all(promises);
          }

          history.push('/customers');
          message.success(t('messages:successUpdateCustomer'));
        }
      } catch (error) {
        console.log('error -> handleSave -> ', error);
        if (error.response.status === 400) {
          message.error('Cliente tem contratos ativos!');
        }

        const erro = console.log(error);
        errorHandler(error);

        if (erro.ssn && erro.ssn[0]) {
          switch (erro.ssn[0]) {
            case 'O campo ssn já está sendo utilizado.':
              if (values.business) {
                erro.ssn[0] = 'Esse CNPJ já está sendo utilizado.';
                message.error('Esse CNPJ já está sendo utilizado.');
              } else {
                erro.ssn[0] = 'Esse CPF já está sendo utilizado.';
                message.error('Esse CPF já está sendo utilizado.');
              }
              break;
            case 'O campo ssn deve ser 11 caracteres.':
              erro.ssn[0] = 'CPF inválido';
              break;
            case 'O campo ssn deve ser 14 caracteres.':
              erro.ssn[0] = 'CNPJ inválido';
              break;
            default:
              break;
          }
        }

        setLoading(false);
        setErrors(erro);
      }
    }
    setLoading(false);
  };

  const safeGet = (obj, prop, defaultValue) => {
    try {
      return obj[prop];
    } catch (e) {
      return defaultValue;
    }
  };

  const handleBack = () => {
    history.push('/customers');
  };

  const fetchRecord = async id => {
    setLoading(true);
    try {
      const { data } = await api.get(`/customers/${id}`);
      data.phone = data.phone ? dynamicFormatPhone(data.phone) : null;

      setRecordData(data);
      setCustomerType(data.business);
      setContactsData(data.contacts);
      setTableContactsData(data.contacts);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchContracts = async (id, page) => {
    setLoading(true);
    try {
      const params = {
        page,
        perPage,
        customer_id: id,
      };

      const { data } = await api.get('/contracts/all', {
        params,
      });

      if (id) {
        const tags = await api.get(`/contracts/tags/${id}`);
        setTags(tags.data);
      }

      data.data.sort((x, y) => {
        return safeGet(x.product, 'product', Infinity) - safeGet(y.product, 'product', Infinity);
      });

      setContractsData(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeType = async (type, record) => {
    setLoading(true);
    try {
      if (type === 'padrao') {
        if (record.type.includes(type)) {
          const newType = await record.type.filter(item => item !== type);
          record.type = newType;
        } else {
          let defaultContact = [];
          defaultContact = contactsData.map(contact => {
            if (contact.type.includes(type)) {
              return true;
            }
            return false;
          });
          if (defaultContact.includes(true)) {
            message.error('Apenas um contato pode ser do tipo padrão');
          } else {
            await record.type.push(type);
          }
        }
      } else if (record.type.includes(type)) {
        const newType = await record.type.filter(item => item !== type);
        record.type = newType;
      } else {
        await record.type.push(type);
      }
    } catch (error) {
      console.log('error -> handleChangeType -> ', error);
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Campo obrigatorio';
    }

    if (values.email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Campo invalido';
      }
    }
    return errors;
  };

  const handleFilterContactTable = contacts => {
    const filteredContacts = contacts.filter(contact => !contact.delete);
    setTableContactsData(filteredContacts);
  };

  const handleNewContact = async values => {
    setLoading(true);
    try {
      values.type = [];
      values.active = true;
      const contacts = contactsData;
      contacts.push(values);
      setContactsData(contacts);
      handleFilterContactTable(contacts);
    } catch (error) {
      console.log('error -> handleNewContact -> ', error);
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handlePutContact = async values => {
    setLoading(true);
    try {
      const contacts = contactsData.filter(contact => contact !== selectedContact);
      contacts.push(values);
      setContactsData(contacts);
      handleFilterContactTable(contacts);
    } catch (error) {
      console.log('error -> handlePutContact -> ', error);
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleInfo = notes => {
    setShowInfo(true);
    setSelectedContract(notes);
  };

  const handleCopy = () => {
    const maskedInputElement = document.getElementById('ssn');
    if (maskedInputElement) {
      const maskedValue = maskedInputElement.value;
      navigator.clipboard
        .writeText(maskedValue)
        .then(() => {
          message.success('CNPJ/CPF copiado!');
        })
        .catch(err => {
          console.error('Falha ao copiar: ', err);
        });
    }
  };

  const handleEdit = record => {
    setSelectedEditContract(record);
    setShowContractsForm(true);
  };

  useEffect(() => {
    if (id) {
      fetchRecord(id);
    } else {
      setRecordData(initialValues);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchContracts(id, page);
    }
  }, [page, perPage]);

  const contractsTableColumns = [
    {
      key: 'product',
      dataIndex: 'product',
      title: t('screens:contracts.data.type'),
      align: 'center',
      render: (text, record) => {
        return record.product.product ? <FaBox title="PRODUTO" /> : <FaTools />;
      },
    },
    {
      key: 'category',
      dataIndex: 'category.name',
      title: t('screens:contracts.data.category'),
      render: (text, record) => {
        return <SpanUpper>{record.product.subcategory.category.name}</SpanUpper>;
      },
    },
    {
      key: 'subcategory',
      dataIndex: 'subcategory.name',
      title: t('screens:contracts.data.subcategory'),
      render: (text, record) => {
        return <SpanUpper>{record.product.subcategory.name}</SpanUpper>;
      },
    },
    {
      key: 'product_id',
      dataIndex: 'product.name',
      title: t('screens:contracts.data.product_id'),
      render: text => {
        return <SpanUpper>{text}</SpanUpper>;
      },
    },
    {
      key: 'recurrence',
      dataIndex: 'recurrence',
      title: t('screens:contracts.data.recurrence'),
      render: (text, record) => {
        const { name } = recurrences.find(item => item.id === record.recurrence);
        return <SpanUpper>{name}</SpanUpper>;
      },
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: t('screens:contracts.data.price'),
      render: (text, record) => {
        const value = canCostValueView(record, 'price', permissions);
        if (value !== '') {
          return (
            <>
              {t('app:currency.real')} {value}
            </>
          );
        }

        return '';
      },
    },
    {
      key: 'started_at',
      dataIndex: 'started_at',
      title: t('screens:contracts.data.started_at'),
      render: (text, record) => record.started_at && format(new Date(record.started_at), 'dd/MM/yyyy'),
    },
    {
      key: 'expiration',
      dataIndex: 'expiration',
      title: t('screens:contracts.data.expiration'),
      render: (text, record) => record.expiration && format(new Date(record.expiration), 'dd/MM/yyyy'),
    },
    {
      key: 'notes',
      dataIndex: 'notes',
      align: 'center',
      width: '50px',
      title: t('screens:contracts.data.notes'),
      render: (text, record) =>
        text ? (
          <Button onClick={() => handleInfo(record.notes)} style={{ background: 'transparent' }}>
            <FaInfo style={{ color: '#aaa' }} />
          </Button>
        ) : (
          <div style={{ width: '30px' }} />
        ),
    },
    {
      key: 'attach',
      dataIndex: 'attach',
      align: 'center',
      width: '50px',
      render: (text, record) =>
        record?.files?.length > 0 && (
          <TableActions>
            <Button
              title="Anexos"
              style={{ border: 'none', padding: '0', marginLeft: '20px', marginRight: '20px', cursor: 'default' }}
            >
              <FaPaperclip /> <b style={{ fontSize: '10px', fontWeight: '400' }}>{record.files.length}</b>
            </Button>
          </TableActions>
        ),
    },
    {
      key: 'actions',
      title: t('screens:contracts.data.actions'),
      align: 'center',
      width: '70px',
      render: (text, record) => {
        const categoryId = record?.product?.subcategory?.category_id;
        return (
          <TableActions>
            {id &&
              (!permissionDisable(permissions, record) ? (
                <Button title={t('messages:edit')} onClick={() => handleEdit(record)}>
                  <FaPencilAlt />
                </Button>
              ) : (
                <Button title={t('messages:view')} onClick={() => handleEdit(record)}>
                  <FaEye />
                </Button>
              ))}
            <Can role={role}>
              <Popconfirm
                title={t('messages:confirmDelete')}
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={async () => {
                  if (!id) {
                    setNewCustomer(prevCustomers =>
                      prevCustomers.filter(customer => customer.temp_id !== record.temp_id)
                    );
                  } else {
                    await api.delete(`/contracts/${record.id}`);
                    message.success('Contrato excluído');
                    fetchContracts(id, page);
                  }
                }}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button title={t('messages:delete')}>
                  <FaRegTrashAlt />
                </Button>
              </Popconfirm>
            </Can>
            <Can permission="@contract/view">
              <Switch
                style={{ marginTop: '5px' }}
                name="active"
                checked={record.active}
                disabled={permissionDisable(permissions, categoryId)}
                onChange={async e => {
                  record.active = e;
                  delete record.files;
                  await api.put(`/contracts/${record.id}`, record);
                  fetchContracts(id, page);
                }}
              />
            </Can>
          </TableActions>
        );
      },
    },
  ];

  const contactsTableColumns = [
    {
      title: t('screens:contacts.data.name'),
      key: 'name',
      dataIndex: 'name',
      render: text => <SpanUpper>{text}</SpanUpper>,
    },
    {
      title: t('screens:contacts.data.email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('screens:contacts.data.phone'),
      key: 'phone',
      dataIndex: 'phone',
      render: (text, record) => <span>{record.phone ? formatPhone(text.replace(/[^0-9,]*/g, '')) : ''}</span>,
    },
    {
      title: t('screens:contacts.data.type'),
      key: 'types',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return (
          <TableActions>
            <Can permission="@contact/edit">
              <Button
                title={t('messages:buy')}
                onClick={() => {
                  handleChangeType('compras', record);
                }}
              >
                <FaShoppingCart
                  color={record.type.includes('compras') ? '#0f4c81' : '#aaa'}
                  name="buy"
                  size={18}
                  title={t('messages:buy')}
                />
              </Button>
              <Button
                title={t('messages:director')}
                onClick={() => {
                  handleChangeType('diretoria', record);
                }}
              >
                <FaUserTie
                  color={record.type.includes('diretoria') ? '#0f4c81' : '#aaa'}
                  name="director"
                  size={18}
                  title={t('messages:director')}
                />
              </Button>
              <Button
                title={t('messages:financial')}
                onClick={() => {
                  handleChangeType('financeiro', record);
                }}
              >
                <FaDollarSign
                  color={record.type.includes('financeiro') ? '#0f4c81' : '#aaa'}
                  name="financial"
                  size={18}
                  title={t('messages:financial')}
                />
              </Button>
              <Button
                title={t('messages:default')}
                onClick={() => {
                  handleChangeType('padrao', record);
                }}
              >
                <FaStar
                  color={record.type.includes('padrao') ? '#0f4c81' : '#aaa'}
                  name="default"
                  size={18}
                  title={t('messages:default')}
                />
              </Button>
            </Can>
          </TableActions>
        );
      },
    },
    {
      title: t('screens:contacts.data.actions'),
      width: '200px',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableActions>
              <Can permission="@contact/edit">
                <Button
                  title={t('messages:edit')}
                  onClick={() => {
                    record.phone = record.phone ? dynamicFormatPhone(record.phone) : null;
                    setSelectedContact(record);
                  }}
                >
                  <FaPen />
                </Button>
              </Can>
              <Can permission="@contact/delete">
                <Switch
                  style={{ marginTop: '5px' }}
                  name="active"
                  checked={record.active}
                  onChange={value => {
                    record.active = value;
                  }}
                />
              </Can>
            </TableActions>
          </div>
        );
      },
    },
  ];

  const customerSchema = Yup.object().shape({
    name: Yup.string()
      .min(3)
      .required(),
    business: Yup.boolean('Campo Obrigatório').required(),
    ssn: Yup.string().required(),
    website: Yup.string().nullable(),
    nickname: Yup.string().nullable(),
    city_reg: Yup.string().nullable(),
    state_reg: Yup.string().nullable(),
    warning_msg: Yup.string().nullable(),
    address: Yup.string().nullable(),
    address_type: Yup.string().when('address', {
      is: value => value && value.length > 0,
      then: Yup.string('Campo obrigatório').required(),
      otherwise: Yup.string(),
    }),
    address_number: Yup.string().when('address', {
      is: value => value && value.length > 0,
      then: Yup.string('Campo inválido').required(),
      otherwise: Yup.string(),
    }),
    gender: Yup.string()
      .nullable()
      .when('business', {
        is: false,
        then: Yup.string('Campo inválido').required(),
      }),
    address_extra: Yup.string().nullable(),
    neighborhood: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
  });

  return (
    <DefaultLayout>
      <PageTitle
        title={recordData.name ? `${recordData.name}` : null}
        // subtitle=
      />
      {id && !loading && recordData.warning_msg !== '' && recordData.warning_msg !== null && (
        <>
          <Tag color="red">
            <b>AVISO</b>
          </Tag>
          <Tag color="orange" style={{ textTransform: 'uppercase' }}>
            {recordData.warning_msg}
          </Tag>
        </>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '30px',
          paddingBottom: '30px',
        }}
      >
        <PageTitle size={2} title={t('screens:recordData')} />
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            paddingTop: '25px',
          }}
        >
          {tags.length > 0 &&
            tags.map(tag => {
              return <HeaderTags color={tag?.color} text={tag?.name} />;
            })}
        </div>
      </div>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={recordData}
        enableReinitialize
        onSubmit={handleSave}
        validationSchema={customerSchema}
      >
        {({ errors, values }) => (
          <Spin spinning={loading}>
            <Form>
              <Input type="hidden" name="id" />
              <Box>
                {recordData.id && (
                  <HeaderInfo>
                    <span>
                      {`Data do Cadastro
                        ${new Date(recordData.created_at).toLocaleString('pt-BR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}`}
                    </span>
                    <span>
                      {` Alterado em
                        ${new Date(recordData.updated_at).toLocaleString('pt-BR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })} por ${recordData.user ? recordData.user : 'Bom Controle'}`}
                    </span>
                  </HeaderInfo>
                )}
                <Row>
                  <FormControl
                    error={errors.business}
                    cols={{ xs: 24, sm: 12, md: 10, lg: 8, xl: 5 }}
                    field="business"
                    label={t('screens:customers.data.business')}
                  >
                    {!id ? (
                      <Radio.Group
                        name="business"
                        buttonStyle="solid"
                        onChange={record => setCustomerType(record.target.value)}
                        value={customerType}
                      >
                        <Radio.Button value>
                          <FaBuilding /> {t('screens:customers.data.type.business')}
                        </Radio.Button>
                        <Radio.Button value={false}>
                          <FaMale /> {t('screens:customers.data.type.person')}
                        </Radio.Button>
                      </Radio.Group>
                    ) : (
                      <Radio.Group name="business" buttonStyle="solid">
                        {recordData.business ? (
                          <Radio.Button value={1}>
                            <FaBuilding /> {t('screens:customers.data.type.business')}
                          </Radio.Button>
                        ) : (
                          <Radio.Button value={0}>
                            <FaMale /> {t('screens:customers.data.type.person')}
                          </Radio.Button>
                        )}
                      </Radio.Group>
                    )}
                  </FormControl>
                  <Col xs={24} sm={9} md={11} lg={13} xl={16} />
                  <FormControl
                    error={errors.ssn}
                    cols={{ xs: customerType ? 5 : 4 }}
                    field="ssn"
                    label={customerType ? t('screens:customers.data.ssn.cnpj') : t('screens:customers.data.ssn.cpf')}
                    required
                  >
                    {customerType ? (
                      <Field
                        name="ssn"
                        render={({ field }) => {
                          return (
                            <div
                              style={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'rgba(0, 0, 0, 0.65)',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                textAlign: 'left',
                                backgroundColor: '#fafafa',
                                borderRadius: '5px',
                                WebkitTransition: 'all 0.3s',
                                transition: 'all 0.3s',
                                borderRight: '0',
                              }}
                              disabled={id}
                            >
                              <MaskedInput
                                mask="11.111.111/1111-11"
                                {...field}
                                id="ssn"
                                placeholder="__.___.___/____-__"
                                disabled={id}
                              />
                              <Button
                                color="primary"
                                onClick={async () => {
                                  const { value } = document.getElementById('ssn');
                                  handleFindCompany(value, values);
                                }}
                              >
                                <FaSearch />
                              </Button>
                              <Button
                                type="button"
                                color="primary"
                                title="Copiar CNPJ"
                                style={{ marginLeft: '5px' }}
                                onClick={handleCopy}
                              >
                                <FaClone />
                              </Button>
                            </div>
                          );
                        }}
                      />
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Field
                          name="ssn"
                          render={({ field }) => {
                            return (
                              <MaskedInput
                                mask="111.111.111-11"
                                {...field}
                                id="ssn"
                                placeholder="___.___.___-__"
                                disabled={id}
                              />
                            );
                          }}
                        />
                        <Button type="button" color="primary" title="Copiar CPF" onClick={handleCopy}>
                          <FaClone />
                        </Button>
                      </div>
                    )}
                  </FormControl>
                  {customerType && (
                    <FormControl
                      error={errors.nickname}
                      cols={{ xs: 8 }}
                      field="nickname"
                      label={t('screens:customers.data.nickname')}
                    >
                      <Input name="nickname" style={{ textTransform: 'uppercase' }} />
                    </FormControl>
                  )}
                  <FormControl
                    error={errors.name}
                    cols={{ xs: customerType ? 11 : 18 }}
                    field="name"
                    label={
                      customerType ? t('screens:customers.data.name.business') : t('screens:customers.data.name.person')
                    }
                    required
                  >
                    <Input name="name" style={{ textTransform: 'uppercase' }} />
                  </FormControl>
                  {!customerType && (
                    <FormControl
                      error={errors.gender}
                      cols={{ xs: 2 }}
                      field="gender"
                      label={t('screens:customers.data.gender')}
                    >
                      <Select
                        style={{ textTransform: 'uppercase' }}
                        name="gender"
                        allowClear
                        filterOption={false}
                        showSearch
                        loading={loading}
                        disabled={loading}
                      >
                        {customerGenders.map(item => {
                          return (
                            <Select.Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  {customerType && (
                    <FormControl
                      error={errors.city_reg}
                      cols={{ xs: 4 }}
                      field="city_reg"
                      label={t('screens:customers.data.city_reg')}
                    >
                      <Input name="city_reg" style={{ textTransform: 'uppercase' }} />
                    </FormControl>
                  )}
                  {customerType && (
                    <FormControl
                      error={errors.state_reg}
                      cols={{ xs: 4 }}
                      field="state_reg"
                      label={t('screens:customers.data.state_reg')}
                    >
                      <Input name="state_reg" style={{ textTransform: 'uppercase' }} />
                    </FormControl>
                  )}
                  <FormControl
                    error={errors.phone}
                    cols={{ xs: 4 }}
                    field="phone"
                    label={t('screens:customers.data.phone')}
                  >
                    <Input
                      name="phone"
                      maxLength="15"
                      onChange={e => {
                        setTimeout(() => {
                          setRecordData({
                            ...values,
                            phone: dynamicFormatPhone(e.target.value),
                          });
                        }, 1);
                      }}
                      placeholder="(  ) ____-____"
                    />
                  </FormControl>
                  <FormControl
                    error={errors.website}
                    cols={{ xs: customerType ? 12 : 20 }}
                    field="website"
                    label={t('screens:customers.data.website')}
                  >
                    <Input name="website" />
                  </FormControl>
                </Row>
                <FormControl
                  error={errors.observation}
                  cols={{ xs: 24 }}
                  field="observation"
                  label={t('screens:customers.data.observation')}
                >
                  <TextArea name="observation" style={{ textTransform: 'uppercase' }} />
                </FormControl>
                <FormControl
                  error={errors.warning_msg}
                  cols={{ xs: 24 }}
                  field="warning_msg"
                  label={t('screens:customers.data.warning_msg')}
                >
                  <TextArea name="warning_msg" style={{ textTransform: 'uppercase' }} />
                </FormControl>
              </Box>
              <PageTitle size={2} title={t('screens:customers.data.addressData')} />
              <Box>
                <Row>
                  <FormControl
                    error={errors.zipcode}
                    cols={{ xs: 4 }}
                    field="zipcode"
                    label={t('screens:customers.data.zipcode')}
                  >
                    <Field
                      name="zipcode"
                      render={({ field }) => {
                        return (
                          <div
                            style={{
                              position: 'relative',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: 'rgba(0, 0, 0, 0.65)',
                              fontWeight: 'normal',
                              fontSize: '14px',
                              textAlign: 'left',
                              backgroundColor: '#fafafa',
                              border: '1px solid #d9d9d9',
                              borderRadius: '5px',
                              WebkitTransition: 'all 0.3s',
                              transition: 'all 0.3s',
                              borderRight: '0',
                            }}
                          >
                            <MaskedInput
                              mask="11.111-111"
                              {...field}
                              id="zipcode"
                              placeholder="__.___-___"
                              style={{ border: 'none' }}
                            />
                            <Button
                              color="primary"
                              onClick={async () => {
                                const { value } = document.getElementById('zipcode');
                                handleFindAddress(value, values);
                              }}
                            >
                              <FaSearch />
                            </Button>
                          </div>
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl
                    error={errors.address_type}
                    cols={{ xs: 2 }}
                    field="address_type"
                    label={t('screens:customers.data.address_type')}
                  >
                    <Select
                      style={{ textTransform: 'uppercase' }}
                      name="address_type"
                      allowClear
                      filterOption={false}
                      showSearch
                      loading={loading}
                      disabled={loading}
                    >
                      {addressesTypes.map(item => {
                        return (
                          <Select.Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                            {item.id}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    error={errors.address}
                    cols={{ xs: 17 }}
                    field="address"
                    label={t('screens:customers.data.address')}
                  >
                    <Input name="address" loading={loading} disabled={loading} style={{ textTransform: 'uppercase' }} />
                  </FormControl>
                  <FormControl
                    error={errors.address_number}
                    cols={{ xs: 3 }}
                    field="address_number"
                    label={t('screens:customers.data.addressNumber')}
                  >
                    <Input name="address_number" style={{ textTransform: 'uppercase' }} />
                  </FormControl>
                  <FormControl
                    error={errors.address_extra}
                    cols={{ xs: 6 }}
                    field="address_extra"
                    label={t('screens:customers.data.addressExtra')}
                  >
                    <Input name="address_extra" style={{ textTransform: 'uppercase' }} />
                  </FormControl>
                  <FormControl
                    error={errors.neighborhood}
                    cols={{ xs: 6 }}
                    field="neighborhood"
                    label={t('screens:customers.data.neighborhood')}
                  >
                    <Input
                      name="neighborhood"
                      loading={loading}
                      disabled={loading}
                      style={{ textTransform: 'uppercase' }}
                    />
                  </FormControl>
                  <FormControl
                    error={errors.city}
                    cols={{ xs: 8 }}
                    field="city"
                    label={t('screens:customers.data.city')}
                  >
                    <Input name="city" loading={loading} disabled={loading} style={{ textTransform: 'uppercase' }} />
                  </FormControl>
                  <FormControl
                    error={errors.state}
                    cols={{ xs: 4 }}
                    field="state"
                    label={t('screens:customers.data.state')}
                  >
                    <Select
                      name="state"
                      allowClear
                      filterOption={false}
                      showSearch
                      onSearch={handleStateSearch}
                      loading={loading}
                      disabled={loading}
                    >
                      {stateOptions.map(item => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.id}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Row>
              </Box>

              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={selectedContact}
                enableReinitialize
              >
                {({ errors, values, resetForm, setErrors, setValues }) => (
                  <Spin spinning={loading}>
                    <Form>
                      <PageTitle size={2} title={t('screens:contacts.title')} />
                      <Box>
                        <Row>
                          <FormControl
                            error={errors.name}
                            cols={{ xs: 5 }}
                            field="name"
                            label={t('screens:contacts.data.name')}
                            required
                          >
                            <Input name="name" style={{ textTransform: 'uppercase' }} />
                          </FormControl>
                          <FormControl
                            error={errors.email}
                            cols={{ xs: 5 }}
                            field="email"
                            label={t('screens:contacts.data.email')}
                          >
                            <Input name="email" type="email" />
                          </FormControl>
                          <FormControl
                            error={errors.phone}
                            cols={{ xs: 4 }}
                            field="phone"
                            label={t('screens:customers.data.phone')}
                          >
                            <Input
                              name="phone"
                              maxLength="15"
                              id="phone1"
                              onChange={e => {
                                setTimeout(() => {
                                  setValues({
                                    ...values,
                                    phone: dynamicFormatPhone(e.target.value.replace(/[^0-9,]*/g, '')),
                                  });
                                }, 100);
                              }}
                              placeholder="(  ) ____-____"
                            />
                          </FormControl>
                          <Col xs={6}>
                            {selectedContact && selectedContact.name.length > 0 ? (
                              <Can permission="@contact/edit">
                                <Button
                                  style={{ marginTop: '25px' }}
                                  color="primary"
                                  onClick={() => {
                                    const error = validate(values);
                                    setErrors(error);
                                    if (Object.keys(error).length === 0) {
                                      handlePutContact(values);
                                      resetForm();
                                      setSelectedContact(contactInitialValues);
                                    }
                                  }}
                                >
                                  Salvar
                                </Button>
                              </Can>
                            ) : (
                              <Can permission="@contact/edit">
                                <Button
                                  style={{ marginTop: '25px' }}
                                  color="primary"
                                  onClick={() => {
                                    const error = validate(values);
                                    setErrors(error);
                                    if (Object.keys(error).length === 0) {
                                      handleNewContact(values);
                                      resetForm();
                                      setSelectedContact(contactInitialValues);
                                    }
                                  }}
                                >
                                  <FaPlus />
                                </Button>
                              </Can>
                            )}
                          </Col>
                        </Row>
                        <Table
                          pagination={false}
                          rowClassName={record => !record.active && 'desactiveContact'}
                          rowKey="id"
                          size="small"
                          columns={contactsTableColumns}
                          dataSource={tableContactsData}
                        />
                      </Box>
                    </Form>
                  </Spin>
                )}
              </Formik>
              <PageTitle size={2} title={t('screens:customers.data.contracts')}>
                <Can permission="@contract/edit">
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      setSelectedEditContract({});
                      setShowContractsForm(true);
                    }}
                  >
                    <FaPlus /> {t('screens:customers.addContracts')}
                  </Button>
                </Can>
              </PageTitle>
              <Box>
                <Table
                  pagination={false}
                  rowClassName={record =>
                    (!record.active && 'desactiveContact') ||
                    (isBefore(new Date(record.expiration), new Date()) && 'expiratedContract') ||
                    (parseFloat(record.price && record.price.replace(',', '.')) <= 0.01 && 'courtesyContract')
                  }
                  rowKey="id"
                  size="small"
                  dataSource={newCustomer.length > 0 ? newCustomer : contractsData}
                  columns={contractsTableColumns}
                />
                <Pagination
                  onChange={value => {
                    setPage(value);
                  }}
                  meta={meta}
                  perPage={perPage}
                  setPerPage={setPerPage}
                />
                {contractsData?.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      float: 'none',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Tag
                      color="#ffbd85"
                      style={{
                        maxWidth: '180px',
                        border: '0.3px solid #00000038',
                        color: '#000000a3',
                        fontWeight: 'bold',
                      }}
                    >
                      <small>Expirado</small>
                    </Tag>
                    <Tag
                      color="#fcc"
                      style={{
                        maxWidth: '180px',
                        border: '0.3px solid #00000038',
                        color: '#000000a3',
                        fontWeight: 'bold',
                      }}
                    >
                      <small>Cancelado</small>
                    </Tag>
                    <Tag
                      color="#f1ff2670"
                      style={{
                        maxWidth: '180px',
                        border: '0.3px solid #00000038',
                        color: '#000000a3',
                        fontWeight: 'bold',
                      }}
                    >
                      <small>Cortesia</small>
                    </Tag>
                  </div>
                )}
              </Box>
              <Box>
                <Row>
                  <FormActions>
                    <Button onClick={handleBack}>{t('messages:cancel')}</Button>
                    <Can permission="@customer/edit">
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    </Can>
                  </FormActions>
                </Row>
              </Box>
              <FormikErrorFocus align="bottom" duration={200} />
            </Form>
          </Spin>
        )}
      </Formik>
      <ContractsForm
        visible={showContractsForm}
        customerID={props.match.params.id}
        contract={selectedEditContract}
        isNewCustomer={!id}
        newCustomer={newCustomer}
        setNewCustomer={setNewCustomer}
        onClose={() => {
          setShowContractsForm(false);
          setSelectedEditContract({});
          if (id) {
            fetchContracts(recordData.id, page);
          }
        }}
      />
      <InfoModal visible={showInfo} contractNotes={selectedContract} onClose={() => setShowInfo(false)} />
    </DefaultLayout>
  );
}

Customers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

Customers.defaultProps = {
  match: {
    params: {
      id: false,
    },
  },
};
