import React, { useState, useEffect } from 'react';
import { FaPlus, FaPencilAlt, FaRegTrashAlt, FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Tag, Popconfirm, message, Input, Icon, Switch, Upload, Select } from 'antd';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import { Table, TableActions, TableHeader } from '~/components/Table';
import history from '~/services/history';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Pagination from '~/components/Pagination';
import { SpanUpper } from '~/styles/default';

export default function InventoryProducts() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [recordList, setRecordList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const role = '@admin';

  const beforeUpload = file => {
    setLoading(true);

    const isValid = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: .xlsx');
    }
    setLoading(false);
    return isValid;
  };

  const fetchData = async page => {
    setLoading(true);
    try {
      const { data } = await api.get(`/inventoryProducts?page=${page}`, {
        params: {
          search: searchTerm,
          status: statusSearch,
          perPage,
        },
      });
      setRecordList(data.data);
      setMeta(data.meta);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/inventoryProducts/create');
  };

  const handleExportXLS = async () => {
    try {
      const fileInfo = await api.post(`/inventoryProducts/export`, {
        searchTerm,
      });

      const { url } = fileInfo.data;

      window.open(url);

      message.success(t('messages:success'));
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
  };

  const handleEdit = async id => {
    history.push(`/inventoryProducts/${id}/edit`);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`inventoryProducts/${id}`);
      message.success(t('messages:successDeleteInventoryProduct'));
      fetchData(1);
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(page, perPage);
  }, [searchTerm, page, perPage, statusSearch]);

  const tableColumns = [
    {
      title: t('screens:inventoryProducts.data.code'),
      dataIndex: 'code_seq',
      key: 'id',
      render: (text, record) => {
        if (record.code_seq) {
          return <Tag color="green">{record.code_seq}</Tag>;
        }
        return <Tag color="blue">{record.code}</Tag>;
      },
      filters: [
        { text: 'Patrimônio', value: 1 },
        { text: 'Controle', value: 0 },
      ],
      onFilter: (value, record) => {
        return record.permanent_asset === value;
      },
      sorter: (a, b) => {
        if (a.permanent_asset === b.permanent_asset) return b.code_seq - a.code_seq;
        return a.permanent_asset - b.permanent_asset;
      },
    },
    {
      title: t('screens:inventoryProducts.data.product'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <SpanUpper>{record.name}</SpanUpper>,
      sorter: (a, b) => a.name.localeCompare(b.name, 'pt-BR'),
    },
    {
      title: t('screens:inventoryProducts.data.brand'),
      dataIndex: 'brand',
      key: 'brand',
      render: (text, record) => <SpanUpper>{record && record.inventoryBrand && record.inventoryBrand.name}</SpanUpper>,
      sorter: (a, b) => {
        a = a.inventoryBrand === null ? 'Z' : a.inventoryBrand.name;
        b = b.inventoryBrand === null ? 'Z' : b.inventoryBrand.name;
        return a.localeCompare(b, 'pt-BR');
      },
    },
    {
      title: t('screens:inventoryProducts.data.model'),
      dataIndex: 'model',
      key: 'model',
      render: (text, record) => <SpanUpper>{record && record.inventoryModel && record.inventoryModel.name}</SpanUpper>,
      sorter: (a, b) => {
        a = a.inventoryModel === null ? 'Z' : a.inventoryModel.name;
        b = b.inventoryModel === null ? 'Z' : b.inventoryModel.name;
        return a.localeCompare(b, 'pt-BR');
      },
    },
    {
      title: t('screens:inventoryProducts.data.department'),
      dataIndex: 'department',
      key: 'department',
      render: (text, record) => <SpanUpper>{record.department}</SpanUpper>,
      sorter: (a, b) => {
        a = a.department === null ? 'Z' : a.department;
        b = b.department === null ? 'Z' : b.department;
        return a.localeCompare(b, 'pt-BR');
      },
    },
    {
      title: t('screens:inventoryProducts.data.responsible'),
      dataIndex: 'responsible',
      key: 'responsible',
      render: (text, record) => <SpanUpper>{record.responsible}</SpanUpper>,
      sorter: (a, b) => {
        a = a.responsible === null ? 'Z' : a.responsible;
        b = b.responsible === null ? 'Z' : b.responsible;
        return a.localeCompare(b, 'pt-BR');
      },
    },
    {
      title: t('screens:inventoryProducts.data.conservation'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        console.log(record);
        switch (record.status) {
          case 'ATIVO':
            return <Tag color="green">ATIVO</Tag>;
          case 'BAIXADO':
            return <Tag color="gray">BAIXADO</Tag>;
          case 'ESTOQUE':
            return <Tag color="blue">ESTOQUE</Tag>;
          case 'LOCADO':
            return <Tag color="orange">LOCADO</Tag>;
          case 'VENDIDO':
            return <Tag color="red">VENDIDO</Tag>;
          default:
            return null;
        }
      },
      filters: [
        { text: 'ATIVO', value: 'ATIVO' },
        { text: 'BAIXADO', value: 'BAIXADO' },
        { text: 'ESTOQUE', value: 'ESTOQUE' },
        { text: 'LOCADO', value: 'LOCADO' },
        { text: 'VENDIDO', value: 'VENDIDO' },
      ],
      onFilter: (value, record) => {
        const temp = record.status === null ? '' : record.status;
        return temp.indexOf(value) === 0;
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
      // sorter: (a, b) => {
      //   a = a.conservation === null ? 'Z' : a.conservation;
      //   b = b.conservation === null ? 'Z' : b.conservation;
      //   return a.localeCompare(b, 'pt-BR');
      // },
    },
    {
      title: t('screens:products.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return (
          <TableActions>
            <Can permission="@inventoryProducts/edit">
              <Button title={t('messages:edit')} onClick={() => handleEdit(record.id)}>
                <FaPencilAlt />
              </Button>
            </Can>
            <Cannot permission="@inventoryProducts/edit">
              <Can permission="@inventoryProducts/view">
                <Button title={t('messages:view')} onClick={() => handleEdit(record.id)}>
                  <FaEye />
                </Button>
              </Can>
            </Cannot>
            <Can permission="@inventoryProducts/delete">
              <Popconfirm
                title={t('messages:confirmDelete')}
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={() => {
                  handleDelete(record.id);
                }}
                okText={t('messages:yes')}
                cancelText={t('messages:no')}
              >
                <Button title={t('messages:delete')}>
                  <FaRegTrashAlt />
                </Button>
              </Popconfirm>
            </Can>
            <Switch disabled style={{ marginTop: '5px' }} name="active" checked={record.active === 1} />
          </TableActions>
        );
      },
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:inventoryProducts.title')}>
        <Can permission="@inventoryProducts/edit">
          <Button
            color="primary"
            loading={loading}
            onClick={handleNew}
            style={{ display: 'inline-flex', marginLeft: '0.2rem' }}
          >
            <FaPlus />
            {t('screens:inventoryProducts.btnNew')}
          </Button>
        </Can>

        <Can role={role}>
          <Button color="primary" loading={loading} onClick={handleExportXLS} style={{ display: 'inline-flex' }}>
            <Icon type="download" />
          </Button>
        </Can>

        <Can role={role}>
          <Upload
            id="excel"
            name="excel"
            action=""
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={({ file }) => {
              if (file.status !== 'uploading') {
                const formData = new FormData();
                formData.append(`excel`, file);
                api.post(`/inventoryProducts/import`, formData);
              }
            }}
            // fileList={uploadList}
          >
            <Button color="primary" loading={loading} style={{ display: 'inline-flex' }}>
              <Icon type="upload" />
            </Button>
          </Upload>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={value => setSearchTerm(value)} className="search-field" />
          <Select
            placeholder="Filtrar por status"
            style={{ width: '10%' }}
            onChange={value => {
              setStatusSearch(value);
              setPage(1);
            }}
          >
            <Select.Option value="">Filtrar por status</Select.Option>
            <Select.Option value="ATIVO">ATIVO</Select.Option>
            <Select.Option value="BAIXADO">BAIXADO</Select.Option>
            <Select.Option value="VENDIDO">VENDIDO</Select.Option>
            <Select.Option value="ESTOQUE">ESTOQUE</Select.Option>
            <Select.Option value="LOCADO">LOCADO</Select.Option>
          </Select>
        </TableHeader>
        <Table loading={loading} columns={tableColumns} dataSource={recordList} rowKey="counter" pagination={false} />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Box>
    </DefaultLayout>
  );
}
